.btn {
    cursor: pointer;
    font-size: 14px;
    padding: 2px 25px;
    border: none;
    border-radius: 5px;
    font-weight: 500;
}

.secondaryBtn{
    color: #4d4848;
    background-color: #ededed;
}

.primaryBtn{
    color:antiquewhite;
    background-color: rgb(30, 98, 245);
}

.primaryBtn:disabled, .secondaryBtn:disabled {
    cursor: text;
}
