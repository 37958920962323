.root {
    height: 70vh;
  }
  
  .image {
    background-image: url('../../assets/icons/Personal-detalis.svg');
    background-repeat: no-repeat;
    background: cover;
    background-position: center;
    background-size: cover;
  }
  
  .paper {
    box-shadow: none;
    align-items: center;
  }
  
  .paper112 {
    text-align: center;
    justify-content: center;
    display: flex;
    margin-bottom: 5px;
  }
  
  .form {
    width: 100%;
    margin-top: 10px;
  }
  
  .margin {
    margin: 12px;
  }
  
  .textField {
    width: 100%;
    margin-left: 1px;
  }
  
  .but {
    margin-left: 132px;
  }
  
  .buts {
    margin-left: 132px;
    color: white;
    background-color: #007AFF;
  }
  
.react-tel-input .form-control {
    font-size: 16px;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 5px;
    width: 350px !important;
    outline: none;
    padding: 5.5px 0 8.5px 65px !important;
    transition: box-shadow ease .25s,border-color ease .25s;
}

.h4Text{
    text-align: center;
    color: rgb(20, 110, 135);
}

.loading {
    position: fixed;
    left: calc(50% - 25px);
    right: 0;
    top: calc(50% - 25px);
    margin: auto;
    height: 100px;
    width: 100px;
}

.passwordValidation{
    color: #d32f2f;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 4px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
}