/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css?family=Muli');

/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
    padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

/* Set core body defaults */
body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
    list-style: none;
}

/* A elements that don't have a class get default styles */
a {
    text-decoration: none;
    cursor: pointer;
}
a:hover{
  text-decoration: underline;
  cursor: pointer;
}

/* Make images easier to work with */
img {
    max-width: 100%;
    display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
    margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

body {
    background-color: #f7f7f7;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    color: #424147;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cardContainer  {
  background: #ffff;
  padding: 10px;
  border-radius: 5px;
}
.mainTitle{
  font-size: 24px;
    font-weight: 700;
}
.secondTitle{
  font-size: 18px;
  font-weight: 700;
}
.flex-space  {
  display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.flex-end  {
  display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.flex-centre{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.appIcons{
  display: flex;
    flex-direction: row;
    justify-content: space-between;
  border-bottom: 1px solid rgb(237, 233, 233);
}

input[readOnly],
  input[readOnly]:focus,
  input[readOnly]:hover,
  input[readOnly]:active {
    background-color:#ededed !important; 
    border: none !important;
    outline: none !important; /* Remove outline on focus (optional) */
  }

  input{
    border-radius: 5px;
  }

.editButton{
  background: #cfd6cf;
  color: #4a4646;
}

input[disabled] + fieldset.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.padding25{
  padding: 25px;
}
.padding10{
  padding: 10px;
}

.paddingMiddle{
  margin-left: 30px;
  position: relative;
  text-align:center;
}
.marginTop25{
  margin-top: 25px;
}
.marginRight10{
  margin-right: 10px;
}

label{
  font-size: 16px;
    font-weight: 500;
}

.css-mhc70k-MuiGrid-root>.MuiGrid-item {
  padding-top: 10px !important;
}

p{
  font-size: 14px;
}

.cXEXzH{

  display: flex;
  justify-content: center;

}

.bookDemo {
  display: flex;
  height: 95vh; /* 100% viewport height */
}

.left-image-container {
  flex: 1;
}

.right-form-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}