/* loader.css */
.loader_section {
  position: relative;
  z-index: 9999;
}

.main_loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.loadingText {
  text-transform: uppercase;
  color: white;
  font-weight: 200;
  font-size: 20px;
}

#page-loader {
  width: 150px;
  height: 150px;

  circle {
    fill: none;
    stroke-width: 5;
    stroke-linecap: round;
    animation: loader 4s linear infinite;
    transform-origin: center center;

    &:nth-child(1) {
      stroke: #ffc114;
      stroke-dasharray: 50;
      animation-delay: -0.2s;
    }
    &:nth-child(2) {
      stroke: #ff5248;
      stroke-dasharray: 100;
      animation-delay: -0.4s;
    }
    &:nth-child(3) {
      stroke: #19cdca;
      stroke-dasharray: 180;
      animation-delay: -0.6s;
    }
    &:nth-child(4) {
      stroke: #4e88e1;
      stroke-dasharray: 350;
      stroke-dashoffset: -100;
      animation-delay: -0.8s;
      transition: stroke-dashoffset 0.3s ease-in-out; /* Added transition for dashoffset */
    }
  }
}

@keyframes loader {
  50% {
    transform: rotate(360deg);
  }
}
