.root {
    height: 100vh !important;
  }

  .loginMain{
    height: 96vh !important;
  }

  .main{
    background-image: url('../../assets/icons/Subtle-Prism-1.svg');
    background-origin: initial;
    background-attachment: fixed;
    background-clip: initial;
    background-position-x: center;
    background-position-y: center;
    background-color: #fafafa;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    
  }
  .sideimage {
    background-repeat: no-repeat;
    background: cover;
    background-position: center;
    background-size: cover;
    align-items: center;
    position: fixed;
    top: calc(50% - 25px);
    left: calc(15% - 25px);

  }
  
  .paper {
    background-color: white;
    padding: 16px; /* Assuming theme.spacing(3) is equivalent to 16px */
    border: 1px solid #fff;
    border-radius: 0px 12px 12px 0px;

    @media (min-width: 600px) {
        padding: 16px; /* Adjust this value based on your theme.spacing(3) */
        border-radius: 0px 12px 12px 0px;
    }
  }
  
  .layout {
    margin-top: 8px; /* Assuming theme.spacing(1) is equivalent to 8px */
    width: auto;
    margin: 100px auto 71px;

    @media (max-width: 583px) {
        margin-top: 64px; /* Adjust this value based on your theme.spacing(8) */
        width: 400px;
        margin: 100px auto 71px;
        padding: 0;
    }

    @media (max-width: 400px) {
        width: auto;
        margin: 100px auto 71px;
    }

    @media (min-width: 600px) {
        margin-top: 64px; /* Adjust this value based on your theme.spacing(8) */
        width: 800px;
        margin: 100px auto 71px;
        padding: 0;
    }
}
  
  .paper1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: calc(25% - 25px);
    margin: 20px;
  }
  
  .form {
    margin-top: 20px;
  }
  
  .margin {
    margin: 12px;
  }
  
  .textField {
    width: 100%;
    margin-left: 1px;
  }
  
  .but {
    margin-left: 132px;
  }
  
  .buts {
    margin-left: 132px;
    color: white;
    background-color: #007AFF;
  }
  
.react-tel-input .form-control {
    font-size: 16px;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 5px;
    width: 353px !important;
    outline: none;
    padding: 5.5px 0 8.5px 65px !important;
    transition: box-shadow ease .25s,border-color ease .25s;
}

.h4Text{
    text-align: center;
    color: rgb(20, 110, 135);
}

.loading {
    position: fixed;
    left: calc(25% - 25px);
    right: 0;
    top: calc(40% - 25px);
    margin: auto;
    height: 100px;
    width: 100px;
}

.passwordValidation{
    color: #d32f2f;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 4px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
}